.sorting {
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px;
    width: 100%;
    z-index: 10;
    background-color: #fff;
    button {
      max-height: 25px;
    }

  }