* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-style: normal;
  font-family: "Golos Text" !important;
}

body {
  font-family: "Golos Text", "Segoe UI", "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFFFF;
  max-height: 100vh;
  overflow-y: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app {
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .icon {
    display: flex;
    align-items: center;
    margin-top: 20px;
    a {
      text-decoration: none;
    }
  }
}