.info {
  padding: 35% 16px 25% 16px;
  button {
    cursor: pointer;
  }
  .logo_wrapper {
    width: 100%;
    height: 100%;
    max-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .logo {
      width: 100%;
      height: 100%;
      max-width: 150px;
      max-height: 74px;
      margin-bottom: 15px;
    }
  }
  p,
  ul {
    color: #717384;
    font-size: 12px;
  }

  .first_description {
    margin-bottom: 20px;
  }

  .title {
    font-size: 14px;
    color: black;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .download_block {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      a:last-child {
        margin-left: 10px;
      }
    }
  }
  .person_block {
    margin-bottom: 20px;
    .person_item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;
      .icons {
        display: flex;
        flex-direction: row;
      }
      .name {
        min-width: 150px;
        text-align: center;
      }
      .name,
      .icon {
        background-color: #f4f5ff;
        padding: 10px 20px;
        border-radius: 5px;
        border: 0.5px solid #e5e6f3;

        p {
          color: black;
          font-weight: 500;
        }
      }
      .icons {
        margin-left: 5%;
        svg {
          max-width: 18px;
          max-height: 18px;
        }
        .icon {
          margin-right: 5px;
        }
      }
    }
  }
  .bottom_buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    button {
      border-radius: 60px;
    }
    svg {
      max-width: 18px;
      max-height: 18px;
    }
    a {
      margin-right: 10px;
    }
  }
}
