@import "../../../../styles/variables.scss";

.card {
  position: relative;
  width: 100%;
  margin: 5px;
  box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
  min-height: 150px;
  border-radius: 5px;
  margin-bottom: 20px;
  h6 {
    line-height: 1.2 !important;
    margin-bottom: 5px;
  }

  .card_result_name {
    color: $grayShark;
    text-align: center;
    font-weight: 500;
    font-size: 8px;
  }

  .card_main {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 10px;

    .card_main__img {
      position: relative;
      margin-top: 25px;
      padding: 0 2px;
      border-radius: 4px;
      max-width: 85px;
      max-height: 78px;

      .card_main__img__device {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $gray;
        padding: 10px;
        max-width: 20px;
        max-height: 20px;
        border-radius: 2px;
        position: absolute;
        top: 10px;
        right: 2px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

        svg {
          width: 8px;
          height: 9px;
        }
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .card_main__description {
      width: 100%;
      margin-top: 10px;
      p {
        font-size: 16px;
      }
    }
  }
  .chip,
  .chip_best,
  .chip_version {
    max-height: 25px;
    min-width: 65px;
    font-weight: 500;

  }

  .chip_best {
    display: flex;
    flex-direction: row-reverse;
    background-color: $orange;
    svg {
      width: 14px;
      height: 14px;
      path {
        fill: white;
      }
    }
    span {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  .chip_version {
    background-color: black;
  }

  .card_result {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-wrap: nowrap;
    margin-bottom: 20px;

    p {
      font-weight: 500;
    }
  }

  .button_delete {
    position: absolute;
    right: 5%;
    bottom: 5%;
    background-color: #FFD3D1;
    min-width: 33px;
    max-width: 33px;
    max-height: 22px;
    border-radius: 5px;

    svg {
      width: 14px;
      height: 15px;
    }
  }
  .card_three_d {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    text-wrap: nowrap;

    .card_three_d__version_result {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 78%;
      height: 30px;
      padding: 0 15px 0 10px;
      border-radius: 26px;
      background: linear-gradient(to right, #f82c95, #ff291c, #ff7401);

      p {
        color: #fff;
        font-size: 12px;
      }
    }
    .card_three_d__version {
      margin-left: 10px;
      text-wrap: wrap;
      text-align: center;
    }
  }
}

.Progress,
.Three_d {
  border-radius: 10px;
  padding: 4px;
  margin-bottom: 5px;
  span {
    border-radius: 0 5px 5px 0;
  }
  margin-bottom: 5px;
}
.Progress {
  span {
    background-color: #001aff !important;
  }
  background-color: #dcdff9 !important;
}
.Three_d {
  span {
    // background-color: linear-gradient(to right, "FF291C", "F82BBE") !important;
    background: linear-gradient(to right, #f82c95, #ff291c, #ff7401) !important;
  }
  background-color: #ffd8d9 !important;
}
// .youtube {
//   pointer-events: none;
// }
