.finish {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5%;
    img {
        width: 100%;
        height: 100%;
        max-width: 78px;
        max-height: 78px;
        margin-bottom: 40px;
    }
}