.rating_view {
  width: 100%;
  max-height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 16px;
 

  .rating_list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100wh;
    height: 100%;
    margin-top: 40px;
  }
  .no_found, .loader {
    margin-top: 40%;
  }
}
