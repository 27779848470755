.Menu {
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #fff;
  margin: 0 20px 0 20px;
  width: 100%;
  padding: 5px;
  height:  100%;
  max-height: 86px;
  display: flex !important;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.icon_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 32px;
  min-width: 32px;
  max-width: 50px;
  cursor: pointer;
  p {
    margin-top: 10px;
    font-size: 10px;
  }
}
.icon_wrapper:hover {
  opacity: 0.8;
}
.active{
  color: blue;
}
.active svg path {
    fill: blue;
}