.profile {
  width: 100%;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 25%;
  .profile_title {
    margin: 10px;
  }
  .profile_item {
    max-width: 100vh;
    overflow: hidden;
    h6 {
      margin: 10px 10px 20px 10px;
    }
    width: 100%;
  }
  .no_found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-top: 40%;
  }
}
