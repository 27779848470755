.header {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #FFFFFF;
    width: 100%;
    height: 10%;
    z-index: 10;
    p {
      color: black;
      font-size: 16px;
      font-weight: 500;
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5%;

    span {
      color: #717384;
    }

    .buttons {
      background-color: #f4f5ff;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1%;
      button {
        border: none;
        padding: 7px 5px;
      }
      .button {
        background-color: transparent;
      }
      .active_button {
        background-color: white;
        border-radius: 5px;
      }
    }
  }