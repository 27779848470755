.add_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f9f9ff;
  width: 100%;
  height: 90vh;
  overflow: auto;
  .title {
    color: black;
    font-size: 16px;
    font-weight: 500;
  }

  .add_wrapper_header {
    width: 100%;
    padding: 5% 3%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    background-color: #ffffff;
  }

  .form_wrapper {
    padding: 0 5% 15% 5%;
    margin-bottom: 50px;
  }
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20%;
    height: 100%;
    margin: 10px 0;
    width: 80vw;
  }
  .buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    button {
      width: 90%;
      margin: 2%;
      color: white;
      min-height: 46px;
      border-radius: 6px;
    }
    button:first-child {
      background-color: #001aff;
    }
    button:last-child {
      color: silver;
    }
  }
}
